import CartPage from '../views/CartPage.vue'
import HomePage from '../views/HomePage.vue'
import OrdersHistoryPage from '../views/OrdersHistoryPage.vue'
import Vue from 'vue'
import VueCookies from "vue-cookies";
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueCookies);
Vue.use(VueRouter);

const cookies = Vue.$cookies;
/**
 * List of available routes
 */
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'homePage',
    component: HomePage
  },
  {
    path: '/cart/',
    name: 'cartPage',
    component: CartPage
  },
  {
    path: '/storico-ordini/',
    name: 'historyPAge',
    component: OrdersHistoryPage
  },
  {
    path: '/:id([^/]+)?',
    name: 'homePage',
    component: HomePage
  },
]

/**
 * Create the vuejs router
 */
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Before each route check if the user is logged in
router.beforeEach((to, from, next) => {  
  // Variabile per verificare se la rotta esiste
  let page404: boolean = true;

  // Controllo se è la rotta dinamica :id
  routes.forEach(el => {
    // Se è la rotta :id o una delle rotte
    if (el.path === to.path || (el.path === '/:id([^/]+)?' && to.params.id)) {
      page404 = false; // La rotta è valida
    }
  });

  // Verifico se l'utente è autenticato
  let auth = cookies.isKey("_identity");
  // Se l'utente non è autenticato e sta cercando di accedere a /cart/ o /storico-ordini/
  if ((!auth && (to.path === "/cart/" || to.path === "/storico-ordini/"))) {
    return next({ path: "/" }); // Redirect alla home se non autenticato
  }

  // Se la rotta non è trovata (404)
  if (page404) {
    return next({ path: "/" });  // Redirect alla home
  }

  // Procedo alla rotta se tutto è valido
  next();
});







export default router
